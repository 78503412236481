@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');
body {
	font-family: 'Rubik', sans-serif;
}

.ant-table-thead > tr > th {
  background: rgba(49, 130, 206, var(--bg-opacity));
  color: white;
}

#react-qrcode-logo {
  position: absolute;
  height: 71px!important;
  width: 70px!important;
  right: 58px;
  top: 4px;
}